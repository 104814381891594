import { Dispatch, FC, SetStateAction } from 'react'

import {
  ChevronDownIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline'
import { AccordionSummary, Tooltip } from '@mui/material'

import { navigate, routes } from '@redwoodjs/router'

import useAnalytics from 'src/lib/hooks/useAnalytics'

import { LearnerCourseType } from '../types'

interface GridItemSummaryProps {
  expanded: number[]
  setExpanded: Dispatch<SetStateAction<number[]>>
  intercomTargetCourseId: number
  learnerCourse: LearnerCourseType
}
const GridItemSummary: FC<GridItemSummaryProps> = ({
  expanded,
  setExpanded,
  intercomTargetCourseId,
  learnerCourse,
}) => {
  const expandAccordion = (panelId: number) => {
    if (expanded.includes(panelId)) {
      const tempExpanded = expanded.filter((current) => current !== panelId)
      setExpanded(tempExpanded)
    } else {
      setExpanded([...expanded, panelId])
    }
  }
  const { trackEvent } = useAnalytics()
  return (
    <AccordionSummary
      className="border border-gray-300 border-solid rounded"
      expandIcon={
        <ChevronDownIcon
          className="w-5 h-5 cursor-pointer"
          onClick={() => {
            expandAccordion(learnerCourse.id)
            trackEvent('Course Reporting', 'toggle drop down course', {
              courseName: learnerCourse.name,
            })
          }}
          data-testid={`expand-icon-${learnerCourse.name}`}
          data-intercom-target={
            intercomTargetCourseId === learnerCourse.id
              ? 'intercom-target-course-drop-down'
              : null
          }
        />
      }
      aria-controls="panel2a-content"
      id="panel2a-header"
      sx={{
        '& .MuiAccordionSummary-content': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '24px',
        },
      }}
    >
      <span className="capitalize">{learnerCourse.name}</span>
      <div className="flex items-center gap-2 mr-4">
        <Tooltip
          title={<span className="py-0.5 block">Completed</span>}
          arrow
          componentsProps={{
            tooltip: { className: 'bg-gray-700 text-sm' },
            arrow: { className: 'text-gray-700' },
          }}
        >
          <div className="w-8 h-5">
            <div className="w-8 text-xs text-gray-500 text-center mx-auto">
              {learnerCourse.reporting.stats.statusStates.COMPLETED || 0}
            </div>
            <div className="w-8 border-green-500 border-2 rounded" />
          </div>
        </Tooltip>
        <Tooltip
          title={<span className="py-0.5 block">In Progress</span>}
          arrow
          componentsProps={{
            tooltip: { className: 'bg-gray-700 text-sm' },
            arrow: { className: 'text-gray-700' },
          }}
        >
          <div className="w-8 h-5">
            <div className="w-8 text-xs text-gray-500 text-center mx-auto">
              {learnerCourse.reporting.stats.statusStates.IN_PROGRESS || 0}
            </div>
            <div className="w-8 border-blue-500 border-2 rounded" />
          </div>
        </Tooltip>
        <Tooltip
          title={<span className="py-0.5 block">Not Started</span>}
          arrow
          componentsProps={{
            tooltip: { className: 'bg-gray-700 text-sm' },
            arrow: { className: 'text-gray-700' },
          }}
        >
          <div className="w-8 h-5">
            <div className="w-8 text-xs text-gray-500 text-center mx-auto">
              {learnerCourse.reporting.stats.statusStates.NOT_STARTED}
            </div>
            <div className="w-8 border-red-500 border-2 rounded" />
          </div>
        </Tooltip>
        <span className="text-sm ml-4">
          <ArrowTopRightOnSquareIcon
            className="w-5 h-5 cursor-pointer"
            data-intercom-target={
              intercomTargetCourseId === learnerCourse.id
                ? 'intercom-target-course-expand'
                : null
            }
            onClick={() => {
              navigate(routes.learnerCourseSummary({ id: learnerCourse.id }))
              trackEvent('Course Reporting', 'expand course', {
                courseName: learnerCourse.name,
              })
            }}
          />
        </span>
      </div>
    </AccordionSummary>
  )
}

export default GridItemSummary
