import { FC } from 'react'

// import { LearnerCategory } from 'types/graphql'

import { NoItemsFound } from '../helpers'
import { Membership } from '../interfaces'
import { LearnerCategoryType } from '../types'

import GridItem from './GridItem'
interface ReportsGridProps {
  learnerCategories: LearnerCategoryType[]
  memberships: Membership[]
  selectedCourses: any
}

const ReportsGrid: FC<ReportsGridProps> = ({
  learnerCategories,
  memberships,
  selectedCourses,
}) => {
  // Set filteredLearnerCategories based on the presence of selectedCourses
  const filteredLearnerCategories = selectedCourses.length
    ? learnerCategories.filter((learnerCategory) =>
        selectedCourses.some((selectedCourse) =>
          learnerCategory.learnerCourses.some(
            (learnerCourse) => learnerCourse.id === selectedCourse.id,
          ),
        ),
      )
    : learnerCategories

  // make a variable, mostActiveCourse where mostActiveCourse is the learnerCourse that has the most amount of Completed or In Progress attempts
  // this variable is made so we can target the intercom tour to the most active course. So the tour looks better
  // regan TODO remove this block of code when intercom tour is removed

  const mostActiveCourse = learnerCategories.reduce(
    (acc, current) => {
      return current.learnerCourses.reduce((acc, current) => {
        const completedAttempts = current.reporting.attempts.filter(
          (attempt) => attempt.status === 'COMPLETED',
        )
        const inProgressAttempts = current.reporting.attempts.filter(
          (attempt) => attempt.status === 'IN_PROGRESS',
        )
        const totalAttempts =
          completedAttempts.length + inProgressAttempts.length

        if (totalAttempts > acc.totalAttempts) {
          return {
            totalAttempts,
            learnerCourse: current,
          }
        }

        return acc
      }, acc)
    },
    { totalAttempts: 0, learnerCourse: null },
  )

  // the intercomTarget course is the mostActive course, unless there are no attempts, in which case it is the first course in
  const intercomTargetCourseId =
    mostActiveCourse.totalAttempts > 0
      ? mostActiveCourse.learnerCourse.id
      : learnerCategories[0]?.learnerCourses[0]?.id

  return (
    <div className="px-6 pb-10">
      {!filteredLearnerCategories.length && <NoItemsFound />}
      {filteredLearnerCategories.map((learnerCategory) => (
        <GridItem
          key={learnerCategory.id}
          learnerCategory={learnerCategory}
          memberships={memberships}
          intercomTargetCourseId={intercomTargetCourseId}
        />
      ))}
    </div>
  )
}

export default ReportsGrid
