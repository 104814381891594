import { FC, useState } from 'react'

// import { LearnerCategory } from 'types/graphql'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'

import { Membership } from '../interfaces'
import { LearnerCategoryType } from '../types'

import GridItemRecord from './GridItemRecord'
import GridItemSummary from './GridItemSummary'

interface GridItemProps {
  learnerCategory: LearnerCategoryType
  memberships: Membership[]
  intercomTargetCourseId: number
}

const GridItem: FC<GridItemProps> = ({
  learnerCategory,
  memberships,
  intercomTargetCourseId,
}) => {
  const [expanded, setExpanded] = useState<number[]>([])

  return (
    <div className="mb-4">
      <div className="flex gap-2 items-center mb-2">
        <p className="uppercase text-gray-400 py-4">{learnerCategory.name}</p>
        <div className="h-1 grow border-b border-gray-300"></div>
        <div className="flex">
          <div className="my-auto ml-4 mr-1 h-2 w-2 rounded bg-green-500" />
          <span className="text-gray-500 text-xs">Completed</span>
        </div>
        <div className="flex">
          <div className="my-auto ml-2 mr-1 h-2 w-2 rounded bg-blue-500" />
          <span className="text-gray-500 text-xs">In Progress</span>
        </div>
        <div className="flex">
          <div className="my-auto ml-2 mr-1 h-2 w-2 rounded bg-red-500" />
          <span className="text-gray-500 text-xs">Not Started</span>
        </div>
      </div>
      <div className="">
        {!learnerCategory?.learnerCourses.length && (
          <p className="text-sm text-gray-400 py-4">
            No Learner Course Records Found.
          </p>
        )}
        {learnerCategory?.learnerCourses.map((learnerCourse, index) => {
          return (
            <Accordion
              key={index}
              disableGutters
              className="before:content-[unset] mb-4 bg-white"
              elevation={0}
              expanded={expanded.includes(learnerCourse.id)}
              TransitionProps={{
                timeout: 0,
              }}
              sx={{
                '& .MuiAccordionSummary-root': {
                  cursor: 'default !important',
                },
              }}
            >
              <GridItemSummary
                intercomTargetCourseId={intercomTargetCourseId}
                expanded={expanded}
                setExpanded={setExpanded}
                learnerCourse={learnerCourse}
              />
              {expanded.includes(learnerCourse.id) && (
                <AccordionDetails className="border border-gray-300 border-solid border-t-0 rounded-b pt-2 -mt-2 pb-0">
                  {!learnerCourse.reporting.attempts.length && (
                    <p
                      className="text-sm text-gray-400 py-4"
                      data-testid="course-reporting-no-records-found"
                    >
                      No Learner Course Progress Records Found.
                    </p>
                  )}
                  {learnerCourse.reporting.attempts.map((attempt, index) => (
                    <GridItemRecord
                      key={index}
                      memberships={memberships}
                      attempt={attempt}
                    />
                  ))}
                </AccordionDetails>
              )}
            </Accordion>
          )
        })}
      </div>
    </div>
  )
}

export default GridItem
