import { FC } from 'react'

import { Tooltip } from '@mui/material'

export interface OverviewItemProps {
  title: string
  value: string
  icon: JSX.Element
  tooltipTitle: string
  metricType: string
}

const OverviewItem: FC<OverviewItemProps> = ({
  title,
  value,
  icon,
  tooltipTitle,
  metricType,
}) => {
  return (
    <Tooltip
      title={<span className="text-base py-0.5 block">{tooltipTitle}</span>}
      arrow
      componentsProps={{
        tooltip: { className: 'bg-grey-700 max-w-[200px]' },
        arrow: { className: 'text-grey-700' },
      }}
    >
      <div className="p-6 2xl:py-10 bg-gray-50 rounded-lg flex gap-2 text-center 2xl:text-left flex-col-reverse 2xl:flex-row items-center justify-end 2xl:justify-between">
        <div>
          <span className="block font-bold text-lg">{`${value}${
            metricType === 'percent' ? '%' : ''
          }`}</span>
          <span className="block text-indigo-600 text-sm uppercase">
            {title}
          </span>
        </div>
        {icon}
      </div>
    </Tooltip>
  )
}

export default OverviewItem
