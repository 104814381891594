import { FC, useEffect, useState } from 'react'

import { captureException } from '@sentry/browser'
import {
  LearnerCourseReportsQuery,
  LearnerCourseReportsQueryVariables,
} from 'types/graphql'

import type { CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import CourseReports from '../CourseReports'
import { ACLPrincipalMembership, ACLPrincipalMembershipGroup } from '../types'

const prefillMemberships = (variables, memberships) =>
  variables?.membershipIds
    ? memberships
        .filter((membership) =>
          variables.membershipIds?.includes(membership.id),
        )
        .map((membership) => {
          return {
            id: membership.id,
            user: {
              id: membership.user?.id,
              name: membership.user?.name,
              avatarUrl: membership.user?.avatarUrl,
            },
          }
        })
    : []

const prefillMembershipGroups = (variables, membershipGroups) =>
  variables?.membershipGroupIds
    ? membershipGroups
        .filter((membershipGroup) =>
          variables.membershipGroupIds?.includes(membershipGroup.id),
        )
        .map((membershipGroup) => {
          return {
            id: membershipGroup.id,
            name: membershipGroup.name,
          }
        })
    : []

export const QUERY = gql`
  query LearnerCourseReportsQuery($reportingFlags: ReportingFlags!) {
    learnerCategories: learnerCategoriesByClientAsAdmin(status: PUBLISHED) {
      id
      name
      isGlobal
      aclPrincipals {
        principalType
        principal {
          __typename
          ... on ACLPrincipalMembership {
            membership {
              id
              user {
                id
                name
                avatarUrl
              }
            }
          }
          ... on ACLPrincipalMembershipGroup {
            membershipGroup {
              id
              name
            }
          }
        }
      }
      learnerCourses(status: PUBLISHED, isAdmin: true) {
        id
        name
        reporting(membershipIds: [], reportingFlags: $reportingFlags) {
          learnerCourseId
          attempts {
            status
            percentage
            relatedTracking {
              ... on LearnerCourseTracking {
                id
                membershipId
                updatedAt
                createdAt
              }
            }
          }
          stats {
            learningItems
            passingPercentage
            averageLearningTime
            averageGrade
            assignedUsers
            completionRate
            statusStates {
              COMPLETED
              IN_PROGRESS
              NOT_STARTED
            }
          }
        }
      }
    }
    memberships: membershipsByClient(activeOnly: true) {
      id
      user {
        name
        avatarUrl
      }
      membershipGroups {
        membershipGroup {
          id
          name
        }
      }
    }
    membershipGroups: membershipGroupsByClient {
      id
      name
    }
  }
`

export const beforeQuery = (props) => ({
  variables: props,
  fetchPolicy: 'cache-and-network',
})

export const Loading = () => <LoadingSpinner />

export const Empty = ({
  learnerCategories,
  memberships,
  membershipGroups,
  refetch,
  variables,
}) => {
  //TODO: REWORK
  const clientMembers = memberships
    .map((member) => {
      return { id: member.id, name: member.user?.name }
    })
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })

  const [selectedMembers, setSelectedMembers] = useState(
    prefillMemberships(variables, memberships),
  )
  const [selectedGroups, setSelectedGroups] = useState(
    prefillMembershipGroups(variables, membershipGroups),
  )

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      // SET MEMBERS FOR QUERY
      // THIS will be updated for production
      variables.membershipIds = selectedMembers.map((member) => member.id)
      variables.membershipGroupIds = selectedGroups.map((group) => group.id)
      await refetch()
    }
    // call the function
    fetchData()
      // make sure to catch any error
      .catch((error) => captureException(error))
  }, [selectedMembers, selectedGroups])

  return (
    <CourseReports
      learnerCategories={learnerCategories}
      memberships={clientMembers}
      membershipGroups={membershipGroups}
      selectedMembers={selectedMembers}
      setSelectedMembers={setSelectedMembers}
      selectedGroups={selectedGroups}
      setSelectedGroups={setSelectedGroups}
    />
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

type Props = CellSuccessProps<
  LearnerCourseReportsQuery,
  LearnerCourseReportsQueryVariables
>

export const Success: FC<Props> = ({
  learnerCategories,
  memberships,
  membershipGroups,
  queryResult,
}) => {
  if (!('variables' in queryResult)) {
    // HACK: To coerce queryResult to be a NonSuspenseCellQueryResult
    throw new Error('Not expecting SuspenseCell')
  }
  const { variables } = queryResult

  const clientMembers = memberships
    .map((member) => {
      return {
        id: member.id,
        name: member.user?.name,
        avatarUrl: member.user?.avatarUrl,
      }
    })
    .sort((a, b) => {
      return a.name > b.name ? 1 : -1
    })

  const [selectedMembers, setSelectedMembers] = useState(
    prefillMemberships(variables, memberships),
  )
  const [selectedGroups, setSelectedGroups] = useState(
    prefillMembershipGroups(variables, membershipGroups),
  )

  const selectedGroupIds = selectedGroups.map((group) => group.id)
  const selectedMemberIds = selectedMembers.map((member) => member.id)

  if (selectedGroupIds.length > 0 || selectedMemberIds.length > 0) {
    learnerCategories = learnerCategories.filter((learnerCategory) => {
      let keep = false
      if (selectedGroupIds.length > 0) {
        const aclEntries = learnerCategory.aclPrincipals.filter(
          ({ principalType }) => principalType === 'MEMBERSHIPGROUP',
        )

        // compare the acl entries to the selected groups, acl entries must include all selected groups
        keep = selectedGroupIds.every((selectedGroupId) =>
          aclEntries.some(
            ({ principal }: { principal: ACLPrincipalMembershipGroup }) =>
              principal.membershipGroup.id === selectedGroupId,
          ),
        )
      }

      if (selectedMemberIds.length > 0) {
        const aclEntries = learnerCategory.aclPrincipals.filter(
          ({ principalType }) => principalType === 'MEMBERSHIP',
        )
        // compare the acl entries to the selected groups, acl entries must include all selected groups
        keep = selectedMemberIds.every((selectedMemberId) =>
          aclEntries.some(
            ({ principal }: { principal: ACLPrincipalMembership }) =>
              principal.membership.id === selectedMemberId,
          ),
        )
      }
      return keep
    })
  }

  return (
    <CourseReports
      learnerCategories={learnerCategories}
      memberships={clientMembers}
      membershipGroups={membershipGroups}
      selectedMembers={selectedMembers}
      setSelectedMembers={setSelectedMembers}
      selectedGroups={selectedGroups}
      setSelectedGroups={setSelectedGroups}
    />
  )
}
