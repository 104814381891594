import { FLAG_FEATURES } from 'api/src/common/enums'

import { Metadata } from '@redwoodjs/web'

import CourseReportingCell from 'src/components/Learner/CourseReports/CourseReportingCell'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

const LearnerCourseReportsPage = () => {
  return (
    <>
      <Metadata
        title="Learner Course Reports"
        description="Learner Course Reports page"
      />
      <FeatureToggle
        feature={FLAG_FEATURES.COURSE_REPORTING}
        InactiveComponent={<FeatureDenied />}
      >
        <PageHeader title="Course Reporting" />

        <CourseReportingCell
          reportingFlags={{
            includeCourseStats: true,
            includeCourseAttempts: true,
            includeTaskStats: false,
            includeTaskType: false,
            lastAttempt: true,
            includeArchived: false,
          }}
        />
      </FeatureToggle>
    </>
  )
}

export default LearnerCourseReportsPage
